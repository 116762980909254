import _rule3 from "../rule/";
import _util2 from "../util";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _rule = _rule3;

var _rule2 = _interopRequireDefault(_rule);

var _util = _util2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
/**
 *  Validates the regular expression type.
 *
 *  @param rule The validation rule.
 *  @param value The value of the field on the source object.
 *  @param callback The callback function.
 *  @param source The source object being validated.
 *  @param options The validation options.
 *  @param options.messages The validation messages.
 */


function regexp(rule, value, callback, source, options) {
  var errors = [];
  var validate = rule.required || !rule.required && source.hasOwnProperty(rule.field);

  if (validate) {
    if ((0, _util.isEmptyValue)(value) && !rule.required) {
      return callback();
    }

    _rule2["default"].required(rule, value, source, errors, options);

    if (!(0, _util.isEmptyValue)(value)) {
      _rule2["default"].type(rule, value, source, errors, options);
    }
  }

  callback(errors);
}

exports["default"] = regexp;
exports = exports["default"];
export default exports;