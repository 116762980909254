import _typeof from "babel-runtime/helpers/typeof";
import _rule3 from "../rule/";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _typeof2 = _typeof;

var _typeof3 = _interopRequireDefault(_typeof2);

var _rule = _rule3;

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function required(rule, value, callback, source, options) {
  var errors = [];
  var type = Array.isArray(value) ? "array" : typeof value === "undefined" ? "undefined" : (0, _typeof3["default"])(value);

  _rule2["default"].required(rule, value, source, errors, options, type);

  callback(errors);
}

exports["default"] = required;
exports = exports["default"];
export default exports;